import React from "react";
import { Link } from "gatsby";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import kebabCase from "lodash/kebabCase";

const TagsComponent = (props) => {
  const tags = props.tags;
  const { isLargerSize = false } = props;
  const { shouldLink = true } = props;

  return (
    <TagsContainer>
      {tags.map((tag) => {
        return (
          <TagComponent
            tag={tag}
            key={tag}
            isLargerSize={isLargerSize}
            shouldLink={shouldLink}
          ></TagComponent>
        );
      })}
    </TagsContainer>
  );
};

const TagComponent = (props) => {
  const tag = props.tag;
  const { isLargerSize = false } = props;
  const { shouldLink = true } = props;

  let color = "#725752";
  switch (tag) {
    case "animations":
    case "accessibility":
    case "architecture":
    case "async/await":
    case "associated domains":
    case "app distribution":
    case "basics":
    case "beyond ios":
    case "book":
    case "combine":
    case "continuous integration":
    case "concurrency":
    case "command line":
    case "core data":
    case "cryptokit":
    case "debugging":
      color = "#725752";
      break;
    case "design patterns":
    case "dependency management":
    case "file system":
    case "graphql":
    case "generics and protocols":
    case "ios":
    case "json":
    case "localization":
    case "machine learning":
    case "networking":
    case "notifications":
    case "objective-c":
    case "observation":
      color = "#7ea39b";
      break;
    case "performance":
    case "persistence":
    case "productivity":
    case "property wrappers":
    case "quick tip":
    case "reactive programming":
    case "security":
    case "storekit":
    case "spritekit":
      color = "#748076";
      break;
    case "swift":
    case "swiftdata":
    case "swiftui":
    case "swift package manager":
    case "technical interview":
    case "testing":
    case "tips":
    case "tools":
    case "beyond iOS":
    case "uikit":
    case "ux":
    case "vacation":
    case "widgetkit":
    case "wwdc":
    case "xcode":
      color = "#76a383";
      break;
    default:
      console.error("Not found tag " + tag);
  }

  let result;
  let titleLabel;

  if (isLargerSize === true) {
    titleLabel = <BigTagTitleLabel>{tag}</BigTagTitleLabel>;
  } else {
    titleLabel = <TagTitleLabel>{tag}</TagTitleLabel>;
  }

  if (shouldLink === true) {
    result = (
      <Link
        to={`/tags/${kebabCase(tag)}/`}
        style={{ textDecoration: "none" }}
        rel="nofollow noindex"
      >
        {titleLabel}
      </Link>
    );
  } else {
    result = titleLabel;
  }

  if (isLargerSize === true) {
    return (
      <BigTagContainer sx={{ background: color }}>{result}</BigTagContainer>
    );
  } else {
    return <TagContainer sx={{ background: color }}>{result}</TagContainer>;
  }
};

export default TagsComponent;

const TagsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
}));

const TagContainer = styled("div")(({ theme }) => ({
  borderRadius: "8px",
  padding: "0px 8px",
  margin: "2px 6px 2px 0px",
  lineHeight: "22px",
}));

const BigTagContainer = styled("div")(({ theme }) => ({
  borderRadius: "10px",
  padding: "2px 10px",
  margin: "3px 8px 3px 0px",
  lineHeight: "24px",
}));

const TagTitleLabel = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  color: "#ffffff",
}));

const BigTagTitleLabel = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  color: "#ffffff",
}));
